<template>
  <div class="body add-yard">
    <div class="form">
      <el-form
        :model="addForm"
        :rules="rules"
        ref="addForm"
        label-width="102px"
        label-position="left"
        class="demo-form dialog-form"
      >
        <el-form-item label="车场名称：" prop="name">
          <el-input type="text" v-model.trim="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="所在区域：" prop="city">
          <el-cascader
            :options="cityList"
            clearable
            v-model="addForm.city"
            @change="handleChangeCity"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="场半径(米)：" prop="radius">
          <el-input-number
            type="text"
            :min="0"
            v-model.trim="addForm.radius"
            controls-position="right"
            @change="handleBluerRadius"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="经度：" prop="longitude">
          <el-input
            type="text"
            v-model.trim="addForm.longitude"
            @blur="handleBluerLon"
          ></el-input>
        </el-form-item>
        <el-form-item label="纬度：" prop="latitude">
          <el-input
            type="text"
            v-model.trim="addForm.latitude"
            @blur="handleBluerLat"
          ></el-input>
        </el-form-item>
      </el-form>
      <div id="container" class="definedMap"></div>
    </div>
  </div>
</template>

<script>
import AMap from "AMap";
import { checkLong, checkLat } from '@/common/utils/index'
import { queryAreaList } from '@/api/lib/api.js'
import {
  insertBusPark,
  updateBusPark,
  queryBusParkById
} from '@/api/lib/bus-api.js'
export default {
  props: {
    itemId: {
      type: [String, Number],
      default: () => { }
    }
  },

  data () {
    return {
      cityList: [],
      addForm: {
        name: null,
        city: [],
        radius: null,
        longitude: null,
        latitude: null,
      },
      rules: {
        name: [{ required: true, message: "请输入车场名称", trigger: "blur" }],
        city: [{ required: true, type: 'array', message: "请选择所在区域", trigger: "change" }],
        radius: [{ required: true, message: "请输入场半径", trigger: "blur" }],
        longitude: [{ required: true, message: "请输入经度", trigger: "blur" }, { validator: checkLong, trigger: 'blur' }],
        latitude: [{ required: true, message: "请输入纬度", trigger: "blur" }, { validator: checkLat, trigger: 'blur' }],
      },
      circle: null,
    };
  },

  methods: {
    handleChangeCity (e) {
      if (e && e.length) {
        //反查出来位置名称，再获取经纬度
        let cityName = "";
        this.cityList.map(item => {
          if (item.value === e[0]) {
            cityName = item.label;
            if (item.children && item.children.length) {
              item.children.map(i => {
                if (i.value === e[1]) {
                  cityName = item.label + i.label;
                }
              })
            }
          }
        });
        //再根据地名获取中心点经纬度
        AMap.service("AMap.Geocoder", () => {
          let geocoder = new AMap.Geocoder({
            city: "",
          });
          geocoder.getLocation(cityName, (status, result) => {
            if (status === "complete" && result.info === "OK") {
              const { lat, lng } = result.geocodes[0].location;
              //更新addform表单的经纬度值
              this.addForm.longitude = lng;
              this.addForm.latitude = lat;
              this.setmarker(lng, lat);
            }
          })
        })
      }
    },
    handleBluerRadius (e) {
      this.drawCircle(this.addForm.longitude, this.addForm.latitude);
    },
    handleBluerLon (e) {
      if (e.target.value && this.addForm.latitude) {
        this.setmarker(e.target.value, this.addForm.latitude)
      }
    },
    handleBluerLat (e) {
      if (e.target.value && this.addForm.longitude) {
        this.setmarker(this.addForm.longitude, e.target.value)
      }
    },
    setmarker (longitude, latitude, radius) {
      if (this.circle) {
        this.circle.setMap(null);
        this.circle = null;
      }
      this.drawCircle(longitude, latitude);
      this.map.setFitView(this.circle)
    },
    drawCircle (longitude, latitude) {
      if (this.circle) {
        this.circle.setMap(null);
        this.circle = null;
      }
      this.circle = new AMap.Circle({
        center: new AMap.LngLat(longitude, latitude),
        radius: this.addForm.radius,
        borderWeight: 3,
        strokeColor: "#EA546D", //#FF33FF,red
        strokeOpacity: 1,
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        strokeStyle: "solid",
        strokeDasharray: [10, 10],
        fillColor: "#de7171",
      })
      this.circle.setMap(this.map);
    },
    loadmap () {
      let self = this;
      let map = new AMap.Map("container", {
        mapStyle: "amap://styles/fresh",
        zoom: 11,
        resizeEnable: true,
        expandZoomRange: true,
        features: ["bg", "road", "building", "point"],
      });
      self.map = map;
      self.map.on('click', function (e) {
        self.addForm.longitude = e.lnglat?.getLng();
        self.addForm.latitude = e.lnglat?.getLat();
        self.drawCircle(e.lnglat.getLng(), e.lnglat.getLat());
      })
      if (this.itemId) {
        this.drawCircle(this.addForm.longitude, this.addForm.latitude);
      }
      self.map.setFitView(self.circle)
    },
    // 新增/修改
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          const data = {
            name: this.addForm.name,
            city: this.addForm.city,
            radius: this.addForm.radius,
            longitude: this.addForm.longitude,
            latitude: this.addForm.latitude
          }
          if (!this.isEdit) {
            insertBusPark(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          } else {
            data.id = this.addForm.id
            updateBusPark(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
                this.$emit('onLoading', false)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },
    // 查询省市接口
    getAreaList () {
      queryAreaList().then((res) => {
        if (res.code === 1000) {
          this.cityList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },

  created () {
    this.getAreaList()
  },
  mounted () {
    if (this.itemId) {
      queryBusParkById({ id: this.itemId }).then((res) => {
        if (res.code === 1000) {
          this.addForm = { ...res.data }

          delete this.addForm.createTime
          delete this.addForm.updateTime
        } else {
          this.$message.error(res.msg)
        }
        this.$nextTick(() => {
          this.loadmap();
        })
      })
      this.isEdit = true
    } else {
      this.isEdit = false
      this.$nextTick(() => {
        this.loadmap();
      })
    }

  }
};
</script>

<style lang="scss" scoped>
.add-yard {
  @include themify() {
    .form {
      display: flex;
    }
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    #container {
      width: 100%;
      height: 40vh;
    }
  }
}
</style>
