<template>
  <div class="yard-management" ref="YardManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddYard"
        v-if="nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >

      <el-button
        type="primary"
        size="small"
        @click="importExcel"
        v-if="nowMenuList.indexOf('导入') >= 0"
        >导入</el-button
      >
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="车场名称：">
          <el-input
            v-model.trim="form.parkName"
            clearable
            placeholder="请输入车场名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="所在区域：">
          <el-cascader
            :options="cityList"
            clearable
            v-model="form.city"
          ></el-cascader>
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            type="primary"
            size="small"
            @click="onSearch"
            :loading="searchLoading"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            :disabled="tableData.length === 0"
            :loading="downLoadStatus"
            @click="exportData"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column label="序号" type="index" width="60"></el-table-column>
      <el-table-column prop="name" label="车场名称"></el-table-column>
      <el-table-column prop="cityStr" label="所在区域"></el-table-column>
      <el-table-column prop="radius" label="半径（米）"></el-table-column>
      <el-table-column prop="longitude" label="经度"></el-table-column>
      <el-table-column prop="latitude" label="纬度"></el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">{{
          statusObj[Number(scope.row.status)]
        }}</template>
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateYard(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="nowMenuList.indexOf('编辑') >= 0"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            :sort="scope.row.status === 0 ? 'danger' : 'primary'"
            @click="onDisableYard(scope.row)"
            v-if="
              scope.row.status === 0
                ? nowMenuList.indexOf('停用') >= 0
                : nowMenuList.indexOf('启用') >= 0
            "
            >{{ scope.row.status === 0 ? "停用" : "启用" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <!-- 新增编辑 -->
    <el-dialog
      :title="title"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="60%"
    >
      <addYard
        ref="addYard"
        :itemId="itemId"
        @close="onDialogClose"
        @onLoading="onLoading"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入" :visible.sync="downVisible" width="30%">
      <uploadFile
        @uploadSuccess="uploadSuccess"
        url="bus/park/importPark"
        class="import"
        :type="3"
      ></uploadFile>
    </el-dialog>
  </div>
</template>

<script>
import { formatDict, getCurent } from '@/common/utils/index'
import { queryDictsByCodes, queryAreaList } from '@/api/lib/api.js'
import { addExcelExport } from '@/api/lib/refreshAlarm.js'

import addYard from './components/addYard'
import uploadFile from '@/components/uploadFile/uploadFile.vue'
import { mapGetters } from 'vuex'

import {
  getBusParkList,
  disableBusPark,
} from '@/api/lib/bus-api.js'


export default {
  name: 'YardManagement',
  components: {
    uploadFile,
    addYard
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      form: {
        parkName: null,
        city: null,
        currentPage: 1,
        pageSize: 10
      },
      total: 0,
      title: '',
      statusObj: {},
      cityList: [],
      dialogVisible: false,
      searchLoading: false,
      editLoading: false,
      exportForm: {},
      downLoadStatus: false,
      downVisible: false
    }
  },
  computed: {
    ...mapGetters({
      nowMenuList: 'nowMenuList'
    }),

  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.YardManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    //导出
    exportData () {
      this.downLoadStatus = true
      delete this.exportForm.currentPage
      delete this.exportForm.pageSize
      let data = {
        baseUrl: 'bus',
        fileName: `车场信息-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: '/park/parkExport'
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    onSearch () {
      this.searchLoading = true
      this.form.currentPage = 1
      this.form.pageSize = 10
      this.getListByField()
    },
    getListByField () {
      let data = { ...this.form }
      if (data.city) {
        data.city = data.city[1]
      }
      getBusParkList(data)
        .then((res) => {
          if (res.code === 1000) {
            this.tableData =
              res.data.list && res.data.list.length > 0
                ? res.data.list
                : []
            this.total = res.data.total

          } else {
            this.tableData = []
            this.total = 0
            this.$message.error(res.msg)
          }
          this.searchLoading = false
          this.exportForm = { ...data }
        })
        .catch(() => {
          this.searchLoading = false
        })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.getListByField()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.form.currentPage = 1
      this.getListByField()
    },

    // 新增车场
    onAddYard () {
      this.title = '新增车场'
      this.itemId = null
      this.dialogVisible = true
    },
    // 编辑
    onUpdateYard (row) {
      this.title = '修改车场'
      this.itemId = row.id
      this.dialogVisible = true
    },
    //  禁用|启用 车场
    onDisableYard (row) {
      if (row.status === 0) {
        const h = this.$createElement
        this.$msgbox({
          title: '消息',
          type: 'warning',
          message: h('p', null, [h('span', null, '确认停用该车场？')]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })
          .then(() => {
            disableBusPark({ id: row.id, status: 1 }).then((res) => {
              if (res.code === 1000) {
                this.$message.success('停用成功')
                row.status = 1
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              showClose: true,
              message: '已取消停用'
            })
          })
      } else {
        disableBusPark({ id: row.id, status: 0 }).then((res) => {
          if (res.code === 1000) {
            this.$message.success('启用成功')
            row.status = 0
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },

    // 关闭弹窗
    onDialogClose (flag) {
      this.dialogVisible = false
      if (flag) {
        if (this.title.indexOf('新增') > -1) {
          this.onSearch()
        } else {
          this.getListByField()
        }
      }
      this.editLoading = false
    },
    //  保存
    onSave () {
      this.$refs.addYard.onSave()
    },
    onLoading (flag) {
      this.editLoading = flag
    },


    //导入
    importExcel () {
      this.downVisible = true
    },
    //导入成功
    uploadSuccess () {
      this.downVisible = false
      this.onSearch()
    },
    // 查询省市接口
    getAreaList () {
      queryAreaList().then((res) => {
        if (res.code === 1000) {
          this.cityList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'CCZT' }).then((res) => {
        if (res.code === 1000) {
          this.statusObj = formatDict(res.data.CCZT)
        }
      })
    }
  },
  created () {
    this.getDicts()
    this.getAreaList()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      this.onSearch()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.yard-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  @include themify() {
  }
}

.theme-project-resourse {
  .yard-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
